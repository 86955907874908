<template>
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M57.3335 12H60C64.4106 12 68 15.5894 68 20V41.6642C66.3288 40.934 64.5386 40.4256 62.6665 40.1762V30.7734H9.3335V60C9.3335 61.4692 10.5308 62.6665 12 62.6665H40.1762C40.4256 64.5386 40.934 66.3288 41.6642 68H12C7.58936 68 4 64.4106 4 60V20C4 15.5894 7.58936 12 12 12H14.6665V6.6665C14.6665 5.19482 15.8613 4 17.3335 4H20C21.4722 4 22.6665 5.19482 22.6665 6.6665V12H49.3335V6.6665C49.3335 5.19482 50.5278 4 52 4H54.6665C56.1387 4 57.3335 5.19482 57.3335 6.6665V12Z" fill="#555775" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M60 44C51.1772 44 44 51.1772 44 60C44 68.8228 51.1772 76 60 76C68.8228 76 76 68.8228 76 60C76 51.1772 68.8228 44 60 44ZM60.0243 66.2399C58.8796 66.2399 57.92 67.1884 57.92 68.3199C57.92 69.4514 58.8796 70.3999 60.0243 70.3999C61.127 70.3999 62.1286 69.4514 62.0781 68.3698C62.1286 67.1801 61.1775 66.2399 60.0243 66.2399ZM57.92 52.2849C57.92 51.029 58.5296 49.9936 59.5099 49.6677C60.4902 49.3992 61.4704 49.9457 61.8906 51.0194C62.0306 51.4029 62.08 51.7864 62.08 52.2178C62.0553 52.817 62.0203 53.4161 61.9853 54.0153C61.9503 54.6145 61.9153 55.2137 61.8906 55.8129C61.8452 56.738 61.7979 57.6631 61.7505 58.5883C61.7031 59.5134 61.6558 60.4386 61.6105 61.3637C61.561 61.9677 61.561 62.5141 61.561 63.1085C61.5199 64.096 60.8609 64.8533 60.0206 64.8533C59.1804 64.8533 58.5296 64.1439 58.4802 63.166C58.4102 61.7234 58.3402 60.2952 58.2702 58.867C58.2001 57.4383 58.1301 56.0096 58.0601 54.5666C58.0353 54.1879 58.0127 53.8068 57.99 53.4257C57.9674 53.0447 57.9447 52.6636 57.92 52.2849Z" fill="#555775" />
  </svg>
</template>

<script>
export default {
  name: "ExpiredTimeSvg"
}
</script>

<style>

</style>
