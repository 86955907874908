<template>
  <div class="call-screen">
    <call-screen-header
      :call-data="callData"
    />
    <bao-video
      class="mb-3 details-container"
      :is-open-from-external-call-share="true"
    ></bao-video>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import CallScreenHeader from "./CallScreenHeader.vue"
import BaoVideo from "@/apps/call_history/CallFeedbackComponents/BaoAudioAndVideo/BaoVideo.vue"

export default {
  name: "CallScreen",
  components: {
    CallScreenHeader,
    BaoVideo
  },
  props: {
    callDetails: {
      type: Object,
      default: () => {},
      nullable: true
    }
  },
  mounted () {
    this.resetCallVideoStore()
    this.setMeetingData({ meetingData: this.videoData, isSetFromExternalSharedCall: true })
  },
  computed: {
    callData () {
      return this.callDetails.call_data || null
    },
    videoData () {
      return this.callDetails.video_data || null
    }
  },
  methods: {
    ...mapActions({
      resetCallVideoStore: "callVideoStore/resetCallVideoStore",
      setMeetingData: "callVideoStore/setMeetingData"
    })
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>

.call-screen {
  background: #F0E7DF;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  @include media-breakpoint-up(lg) {
    .details-container {
      margin: 0 36px 24px;
    }
  }
}

</style>
