<template>
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M56.6654 3.33398L43.332 16.6673M43.332 3.33398L56.6654 16.6673" stroke="#555775" stroke-width="5" stroke-linecap="round" />
    <path d="M41.8529 38.4935L40.3362 40.0935C40.3362 40.0935 36.7262 43.8902 26.8762 33.5202C17.0262 23.1502 20.6362 19.3535 20.6362 19.3535L21.5929 18.3435C23.9462 15.8635 24.1695 11.8835 22.1129 8.97683L17.9129 3.0335C15.3662 -0.566505 10.4495 -1.03984 7.53285 2.03016L2.30285 7.5335C0.85952 9.05683 -0.107147 11.0268 0.00952 13.2168C0.30952 18.8168 2.70285 30.8602 16.0495 44.9168C30.2062 59.8168 43.4895 60.4102 48.9195 59.8735C50.6395 59.7068 52.1328 58.7768 53.3362 57.5102L58.0695 52.5235C61.2695 49.1568 60.3695 43.3902 56.2762 41.0368L49.9095 37.3702C47.2229 35.8268 43.9529 36.2802 41.8529 38.4902" fill="#555775" />
  </svg>
</template>

<script>
export default {
  name: "UnknownCallSvg"
}
</script>

<style>

</style>
