<template>
  <b-modal
    v-model="isModalVisible"
    header-class="p-0"
    dialog-class="w-100 mt-5"
    modal-class="d-flex align-items-center justify-content-center"
    :hide-footer="true"
  >
    <template v-slot:modal-header>
      <div class="w-100 d-flex align-items-center justify-content-between p-3">
        <span class="modal-title">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-2'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_20509_3200)"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8-8-3.589-8-8zm7.26-3.266l-.009-.01a.483.483 0 01-.115-.32v-.692c0-.12.037-.23.115-.322l.004-.004.005-.005a.456.456 0 01.33-.136h.807c.127 0 .24.046.33.136.09.09.137.204.137.331v.691c0 .128-.046.24-.136.331l-.005.004-.004.004a.483.483 0 01-.322.116H7.59a.483.483 0 01-.321-.116l-.01-.008zm.102 7.898l-.008-.01a.483.483 0 01-.116-.321V6.234c0-.12.038-.232.124-.318a.456.456 0 01.33-.137h.615c.124 0 .235.044.318.136a.416.416 0 01.137.319V12.3c0 .127-.047.24-.137.33a.436.436 0 01-.318.124h-.614a.483.483 0 01-.322-.115l-.01-.008z" fill="#555775"/></g><defs><clipPath id="clip0_20509_3200"><path fill="#fff" d="M0 0h16v16H0z"/></clipPath></defs></svg>
          {{ staticText.linkInfo }}
        </span>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8 1.6C4.48 1.6 1.6 4.48 1.6 8c0 3.52 2.88 6.4 6.4 6.4 3.52 0 6.4-2.88 6.4-6.4 0-3.52-2.88-6.4-6.4-6.4zm3.2 8.704l-.896.896L8 8.896 5.696 11.2l-.896-.896L7.104 8 4.8 5.696l.896-.896L8 7.104 10.304 4.8l.896.896L8.896 8l2.304 2.304z" fill="#AAABBA"/></svg>
      </div>
    </template>
    <div class="call-details">
      <div class="call-detail">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="''" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 10.5a10 10 0 11-20 0 10 10 0 0120 0zM10 4.875a.625.625 0 00-1.25 0v6.875a.625.625 0 00.315.543l4.375 2.5a.625.625 0 00.62-1.085L10 11.387V4.874z" fill="#555775"/></svg>
        <div>
          <p class="title">{{ staticText.callDateLabel }}</p>
          <p class="answer">{{ callDate }}</p>
        </div>
      </div>
      <div class="call-detail">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="''" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.313 10.5c.845 0 1.656.316 2.253.879.598.562.934 1.325.934 2.121 0 2.232-.975 4.02-2.575 5.23C14.35 19.92 12.238 20.5 10 20.5s-4.35-.58-5.925-1.77C2.478 17.52 1.5 15.732 1.5 13.5c0-.746.295-1.465.828-2.017a3.263 3.263 0 012.054-.969l.306-.014h10.625zM10 .5c1.127 0 2.208.421 3.005 1.172A3.885 3.885 0 0114.25 4.5c0 1.06-.448 2.078-1.245 2.828A4.388 4.388 0 0110 8.5a4.389 4.389 0 01-3.005-1.172A3.884 3.884 0 015.75 4.5c0-1.06.448-2.078 1.245-2.828A4.389 4.389 0 0110 .5z" fill="#555775" opacity=".8"/></svg>
        <div>
          <p class="title">{{ staticText.whoSharedLabel }}</p>
          <p class="answer">{{ callSharer }}</p>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: "CallDetailsModal",
  data () {
    return {
      isModalVisible: false,
      staticTextDefault: {
        linkInfo: "Link info",
        whoSharedLabel: "Who shared?",
        callDateLabel: "Call date"
      }
    }
  },
  props: {
    callSharer: {
      type: String
    },
    callDate: {
      type: String
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    showModal () {
      this.isModalVisible = true
    },
    hideModal () {
      this.isModalVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>

.modal-title {
  color: $slate80;
}

.call-details {
  .call-detail {
    display: flex;
    align-items: center;

    &:first-child {
      margin-bottom: 16px;
    }

    svg {
      height: 20px;
      width: 20px;
      margin-right: 12px;
      path {
        fill: $slate80;
      }
    }

    p.title {
      font-size: 12px;
      line-height: 14.4px;
      color: $slate40;
      margin-bottom: 4px;
    }

    p.answer {
      color: $slate80;
      margin: 0;
    }
  }
}

</style>
