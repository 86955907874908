<template>
  <button
    class="try-bao"
    @click="openBaoWebsite"
  >
    Try Bao
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'bao-logo'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.351 4.002a.847.847 0 00-.753.456.86.86 0 00.099.946l3.808 4.584-3.672 4.593a.857.857 0 00.127 1.206.848.848 0 001.241-.128l4.106-5.132a.858.858 0 000-1.086l-4.25-5.132a.85.85 0 00-.706-.307z" fill="#555775"/></svg>
  </button>
</template>

<script>
export default {
  name: "TryBaoBtn",
  methods: {
    openBaoWebsite () {
      window.open("https://bao.ai", "_blank").focus()
    }
  }
}
</script>

<style lang="scss" scoped>

.try-bao {
  padding: 14px 16px;
  border: none;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FF5632;
  color: $white;
  transition: 0.3s ease-in;
  svg {
    transition: 0.3s ease-in;
    path {
      fill: $white;
    }
  }
  &:hover {
    svg {
      transform: translateX(4px);
    }
  }
  @include media-breakpoint-up(sm) {
    padding: 19px 24px;
    border-radius: 20px;
  }
}

</style>
