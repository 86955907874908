<template>
  <div class="w-100 h-100">
    <div
      v-if="isLoading"
      class="loading-screen"
    >
      <progress-indicator />
    </div>
    <div
      v-else
      class="w-100 h-100"
    >
      <login-screen
        v-if="showPasswordScreen"
        @show-shared-call-error="redirectToSharedCallErrorScreen"
        @show-shared-call-screen="redirectToSharedCallScreen"
      />
      <error-screen
        v-else-if="showErrorScreen"
        :type="errorScreenType"
        :shared-user-email="sharedUserEmail"
      />
      <call-screen
        v-else-if="showCallSummaryScreen"
        :call-details="callDetails"
      />
    </div>
  </div>
</template>

<script>
import LoginScreen from "./LoginScreen.vue"
import ErrorScreen from "./ErrorScreen.vue"
import CallScreen from "./CallScreen.vue"
import axiosWithoutAuth from "./api"
import { errorScreenTypes } from "./utils"
import { clearStorage } from "@/utils/authenticationManager"
import { mapMutations } from "vuex"
import ProgressIndicator from "@/apps/base/ProgressIndicator.vue"
import { externalShareBaseUrl } from "./index"

export default {
  name: "SharedCall",
  components: {
    LoginScreen,
    ErrorScreen,
    CallScreen,
    ProgressIndicator
  },
  data () {
    return {
      isLoading: true,
      errorScreenType: null,
      sharedUserEmail: null,
      callDetails: null
    }
  },
  mounted () {
    this.getExternalSharedData()
    clearStorage()
  },
  computed: {
    routeQuery () {
      return this.$route.query
    },
    showPasswordScreen () {
      return this.routeQuery.auth
    },
    showErrorScreen () {
      return this.routeQuery.error
    },
    showCallSummaryScreen () {
      return this.routeQuery.summary
    }
  },
  methods: {
    ...mapMutations({
      setTranslationObj: "I18nStore/setI18n"
    }),
    async getExternalSharedData () {
      try {
        const { data } = await axiosWithoutAuth.post(`${externalShareBaseUrl}/validate_request`, {
          encrypted_url_id: this.$route.params.id
        })
        const translationObject = data.translation_obj
        this.setTranslationObj(translationObject)

        // Show password entry screen
        this.redirectToPasswordScreen()
        this.isLoading = false
      } catch (error) {
        console.error(error)
        // Show invalid call error screen
        this.redirectToSharedCallErrorScreen()
        this.isLoading = false
      }
    },
    redirectToPasswordScreen () {
      if (!this.showPasswordScreen) this.$router.push({ query: { auth: true } })
    },
    redirectToSharedCallErrorScreen ({ type = errorScreenTypes.unknown_call, userEmail = "" } = {}) {
      this.errorScreenType = type
      this.sharedUserEmail = userEmail
      if (!this.showErrorScreen) this.$router.push({ query: { error: true } })
    },
    redirectToSharedCallScreen (callDetails) {
      this.callDetails = callDetails
      if (!this.showCallSummaryScreen) this.$router.push({ query: { summary: true } })
    }
  }
}
</script>

<style lang="scss" scoped>

.loading-screen {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F0E7DF;
}

</style>
